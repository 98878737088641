import { Component, OnInit, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'gl-button',
    templateUrl: './gl-button.component.html',
    styleUrls: ['./gl-button.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, MatIconModule]
})
export class GlButtonComponent implements OnInit {
    @Input() type: string = '';
    @Input() size: string = '';
    @Input() icon: string;
    @Input() disabled: boolean;

    public classes: string[];

    constructor() {}

    ngOnInit(): void {
        this.classes = [this.type, this.size];
    }
}
